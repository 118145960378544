module.exports = [{
      plugin: require('/Users/imamachinaoto/Desktop/gatsby-my-blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('/Users/imamachinaoto/Desktop/gatsby-my-blog/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/imamachinaoto/Desktop/gatsby-my-blog/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/imamachinaoto/Desktop/gatsby-my-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
